<script setup lang="ts">
import type { SbBlokData } from '@storyblok/js';

interface IProps {
    blok: LandingImpactStatsBlokInterface;
}
const props = defineProps<IProps>();

const isDark = computed(() => BACKGROUND_IS_DARK[props.blok.background]);
</script>

<template>
    <page-block-wrapper
        v-editable="blok"
        :background="blok.background"
        :disable-bottom-margin="blok.disableBottomMargin">
        <es-container>
            <es-row class="flex-lg-column">
                <es-col
                    lg="8"
                    class="d-flex flex-column justify-content-lg-center">
                    <h2
                        class="font-size-lg-600 mb-150"
                        :class="{ 'text-white': isDark }">
                        {{ blok.heading }}
                    </h2>
                    <div class="bg-blue-50 mb-50 mb-lg-0 pl-200 pr-100 pr-lg-200 py-300 rounded">
                        <es-row>
                            <storyblok-component
                                v-for="(child, index) in blok.children"
                                :key="child._uid"
                                :blok="child as unknown as SbBlokData"
                                :class="{
                                    'mb-150': index < blok.children.length - 1,
                                    'mb-md-0': index >= blok.children.length - 2,
                                    'mb-lg-200': index < blok.children.length - 2,
                                    'mb-lg-0': index >= blok.children.length - 2,
                                }" />
                        </es-row>
                        <p
                            v-if="blok.footnote"
                            class="font-size-sm mb-0 mt-150">
                            {{ blok.footnote }}
                        </p>
                        <div
                            v-for="child in blok.ctas"
                            :key="child._uid"
                            class="mt-200">
                            <storyblok-component :blok="child as unknown as SbBlokData" />
                        </div>
                    </div>
                </es-col>
                <es-col
                    class="LandingImpactStats-image align-self-lg-end mt-lg-n800"
                    lg="6">
                    <storyblok-image
                        :alt="blok?.image?.alt"
                        class="rounded"
                        :src="blok?.image?.filename" />
                </es-col>
            </es-row>
        </es-container>
    </page-block-wrapper>
</template>
